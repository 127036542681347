/*
 * @Author: chengleilei
 * @Date: 2024-01-05 14:23:38
 * @Description: 经销商扫码积分-常量
 */
let monthNow = new Date().getMonth() + 1;
// @ts-ignore
monthNow = monthNow < 10 ? '0' + monthNow : monthNow;
const nowData = `${new Date().getFullYear()}-${monthNow}`;

// 表头
const indexColumns = [
    { title: '经销商名称', key: 'distributor', minWidth: 150, fixed: 'left' },
    {
        title: '虚拟门店名称',
        key: 'virtualStoreStr',
        minWidth: 150,
        render: (h, p) => {
            return [p.row.virtualStoreStr || '-'];
        },
    },
    { title: '扫码时间', key: 'scanMonth', minWidth: 120 },
    { title: '扫码总次数', key: 'scanTotalNumber', minWidth: 140 },
    { title: '异常次数', key: 'abnormalNumber', minWidth: 140 },
    { title: '退货次数', key: 'returnNumber', minWidth: 140 },
    { title: '挂车胎有效次数', key: 'hangingTireNumber', minWidth: 140 },
    { title: '例外轮胎有效次数', key: 'exceptionalTyreNumber', minWidth: 160 },
    { title: '扫码有效次数', key: 'scanValidNumber', minWidth: 140 },
    { title: '预计扫码积分', key: 'scanScore', minWidth: 140 },
    {
        title: '审核扫码积分',
        key: 'auditScore',
        minWidth: 140,
        render: (h, p) => {
            return [p.row.auditScore || '-'];
        },
    },
    {
        title: '审核状态',
        key: 'auditStatus',
        minWidth: 100,
        render: (h, p) => {
            let badge = h('Badge', {
                props: {
                    status: {
                        0: 'error',
                        2: 'success',
                        1: 'blue',
                        3: 'warning',
                    }[p.row.auditStatus],
                    text: {
                        0: '未审核',
                        2: '已审核',
                        1: '审核中',
                        3: '挂起',
                    }[p.row.auditStatus],
                },
            });
            return h('div', [badge]);
        },
    },
];

export { nowData, indexColumns };
