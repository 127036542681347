import request from '@/plugins/request';

/**
 *  通用接口
 */

// 获取档次
export function getLevelConfig(majorBrandId, year) {
    return request({
        url: `/esgin/contract/getLevelConfig/${majorBrandId}/${year}`,
        method: 'get',
    });
}

// 查询所有朝系*（主品牌）
export function AllSeriesList() {
    return request({
        url: '/product/brand/allSeriesList',
        method: 'get',
    });
}

// 任务量查询签约档次
export function taskEsignLevel(majorBrandId = 3, year = '2023') {
    return request({
        url: `/esgin/contract/getLevelConfig/${majorBrandId}/${year}`,
        method: 'get',
    });
}

// 文件上传
export function upload(data) {
    return request({
        url: '/upload/fileUpload',
        method: 'post',
        data,
    });
}

// 查询2023年签约档次
export function queryAreasByList() {
    return request({
        url: `/gaode/findTree`,
        method: 'get',
    });
}
