<!--
 * @Author: chengleilei
 * @Date: 2024-12-17 14:28:00
 * @Description: 签署门店销售任务量
-->
<template>
    <div>
        <PageHeader title="签署门店销售任务量" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane v-for="item in tabList" :key="item.name" :label="item.label" :name="item.name">
                    <YearTab :ref="item.name + 'yearTabRef'" :columns="item.columns" :currentTab="item.name" />
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>

<script>
import YearTab from './components/YearTab';
import { indexColumns } from './const';

export default {
    components: { YearTab },
    data() {
        return {
            currentTab: '2025', // 当前tab
            tabList: [
                {
                    label: '2025年',
                    name: '2025',
                    columns: [...indexColumns],
                },
                // 2025年1月1日起，24年的列表去掉月度、季度完成情况；仅展示年度相关数据
                {
                    label: '2024年',
                    name: '2024',
                    columns:
                        new Date().getFullYear() == 2025
                            ? [...indexColumns.slice(0, 8), ...indexColumns.slice(12)]
                            : [...indexColumns],
                },
                {
                    label: '2023年',
                    name: '2023',
                    columns: [...indexColumns.slice(0, 8), ...indexColumns.slice(12)],
                },
                {
                    label: '2022年',
                    name: '2022',
                    columns: [...indexColumns.slice(0, 8), ...indexColumns.slice(12)],
                },
                {
                    label: '2021年',
                    name: '2021',
                    columns: [...indexColumns.slice(0, 7), ...indexColumns.slice(12)],
                },
            ],
        };
    },
    methods: {
        // 切换tab
        handleTabClick(e) {
            if (e === this.currentTab) {
                return false;
            }
            this.currentTab = e;
            this.$refs[e + 'yearTabRef'][0].getData();
        },
    },
    mounted() {
        this.$refs['2025yearTabRef'][0].getData();
    },
};
</script>
