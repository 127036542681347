<!--
* @Author: zhangping
* @Date: 2024-12-25 09:00:00
* @Description: 商品库存
-->
<template>
    <div class="wi">
        <PageHeader title="商品库存" hidden-breadcrumb>
            <div slot="back" @click="$router.back()"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <TableFormNew ref="tableFormNewRef" :formList="formList" :isShowSearchReset="false"></TableFormNew>
            <div class="pl-25">
                <div class="mb-10">
                    选择近期数据
                    <i
                        class="wi-icon cup"
                        :class="isShowRecentData ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                        @click="changeVisibleStatisticsData"
                    ></i>
                </div>
                <div class="pl-88" v-show="isShowRecentData">
                    <el-checkbox
                        :indeterminate="isIndeterminate"
                        v-model="isSelectAll"
                        @change="changeIsSelectAll"
                        class="mb-10"
                    >
                        全选
                    </el-checkbox>
                    <el-checkbox-group
                        v-model="averageStocCheckedList"
                        @change="(val) => changeCheckItem('averageStock', val)"
                    >
                        <el-checkbox
                            v-for="item in averageStockList"
                            :label="item.value"
                            :key="item.value"
                            class="mb-10"
                        >
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group
                        v-model="averageOutboundCheckedList"
                        @change="(val) => changeCheckItem('averageOutbound', val)"
                    >
                        <el-checkbox
                            v-for="item in averageOutboundList"
                            :label="item.value"
                            :key="item.value"
                            class="mb-10"
                        >
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="tar mb-20">
                <Button type="primary" @click="onSubmit">查询</Button>
                <Button @click="onReset" class="ml-8">重置</Button>
                <Button class="ml-8" type="error" :loading="loadings.export" @click="onExport">导出</Button>
            </div>
            <TablePage border :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import TableFormNew from '@/components/tableFormNew';
import TablePage from '@/components/tablePage';
import { clearInvalidKey } from '@/libs/util.tool.js';
import { apiDistributorSystemUserWarehouseList } from '@/api/setting.js';
import {
    apiProductBrandAllList,
    apiProductDictionaryFind,
    apiActivityFindProductList,
    apiDistributorStockProduct,
    apiDistributorStockProductExport,
} from '@/api/statistics.js';
import {
    stockDetailFormList,
    averageStockDefaultSelect,
    averageOutboundDefaultSelect,
    stockDetailColumns,
    renderColumnsDynamics,
} from './const';
import { averageStocksDict, averageOutboundsDict } from '@/dicts';

export default {
    components: { TableFormNew, TablePage },
    data() {
        return {
            loadings: { export: false },
            formList: [
                ...stockDetailFormList.slice(0, 3),
                {
                    ...stockDetailFormList[3],
                    onQueryChange: (keyword) => {
                        this.getGoods(keyword);
                    },
                },
                ...stockDetailFormList.slice(4),
            ],
            isShowRecentData: false,
            isSelectAll: false,
            isIndeterminate: true,
            averageStockList: averageStocksDict,
            averageOutboundList: averageOutboundsDict,
            averageStocCheckedList: averageStockDefaultSelect,
            averageOutboundCheckedList: averageOutboundDefaultSelect,
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [...stockDetailColumns],
                data: [],
                loading: false,
                noDataText: '亲，没有找到相关记录哦！~',
            },
            checkedList: [],
            statisticsList: [],
        };
    },
    mounted() {
        this.getWarehouse();
        this.getBrand();
        this.getFigure();
        this.getGoods();
    },
    methods: {
        // 获取仓库
        async getWarehouse() {
            try {
                this.formList[0].loading = true;
                const res = await apiDistributorSystemUserWarehouseList({
                    distributorId: this.$route.query.distributorId,
                });
                if ((res || []).length === 1) {
                    this.formList[0].value = [res[0].id];
                    setTimeout(() => {
                        this.getData();
                    }, 0);
                } else {
                    this.tableConfig.noDataText = '请选择仓库';
                }
                this.formList[0].options = (res || []).map((item) => {
                    item.label = `${item.sn} ${item.name}`;
                    item.value = item.id;
                    return item;
                });
                this.formList[0].loading = false;
            } catch (error) {
                this.formList[0].loading = false;
            }
        },
        // 获取品牌
        async getBrand() {
            try {
                this.formList[1].loading = true;
                const res = await apiProductBrandAllList();
                this.formList[1].options = (res || []).map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                this.formList[1].loading = false;
            } catch (error) {
                this.formList[1].loading = false;
            }
        },
        // 获取花纹
        async getFigure() {
            try {
                this.formList[2].loading = true;
                const res = await apiProductDictionaryFind({ dataType: 'TIRE_FIGURE' });
                this.formList[2].options = (res || []).map((item) => {
                    item.label = item.dataValue;
                    item.value = item.dataValue;
                    return item;
                });
                this.formList[2].loading = false;
            } catch (error) {
                this.formList[2].loading = false;
            }
        },
        // 获取商品
        async getGoods(keyword) {
            try {
                this.formList[3].loading = true;
                const res = await apiActivityFindProductList({ productName: keyword, current: 1, size: 50 });
                this.formList[3].options = (res.records || []).map((item) => {
                    item.label = item.title;
                    item.value = item.id;
                    return item;
                });
                this.formList[3].loading = false;
            } catch (error) {
                this.formList[3].loading = false;
            }
        },
        // change-显隐近期数据
        changeVisibleStatisticsData() {
            this.isShowRecentData = !this.isShowRecentData;
        },
        // change-每项复选框
        changeCheckItem(type, value) {
            let checkedCount = value.length;
            let allCount = averageStocksDict.length + averageOutboundsDict.length;
            let oneCheckedList = this.averageStocCheckedList.length;
            let twoCheckedList = this.averageOutboundCheckedList.length;
            if (type === 'averageStock') {
                this.isSelectAll = checkedCount + twoCheckedList === allCount;
                this.isIndeterminate = checkedCount + twoCheckedList > 0 && checkedCount + twoCheckedList < allCount;
            } else {
                this.isSelectAll = checkedCount + oneCheckedList === allCount;
                this.isIndeterminate = checkedCount + oneCheckedList > 0 && checkedCount + oneCheckedList < allCount;
            }
        },
        // change-全选
        changeIsSelectAll(val) {
            this.averageStocCheckedList = val ? averageStocksDict.map((item) => item.value) : [];
            this.averageOutboundCheckedList = val ? averageOutboundsDict.map((item) => item.value) : [];
            this.isIndeterminate = false;
        },
        // 获取参数
        getParams() {
            const { current, size } = this.tableConfig;
            const params = {
                ...this.$refs['tableFormNewRef'].getFormData(),
                avgStockDays: this.averageStocCheckedList.sort((a, b) => a - b),
                avgOutDays: this.averageOutboundCheckedList.sort((a, b) => a - b),
                current,
                size,
            };
            if (!params?.warehouseIdList?.length) {
                this.$Message.warning('请选择仓库');
                return false;
            }
            return clearInvalidKey(params);
        },
        // 获取列表数据
        async getData() {
            try {
                const params = this.getParams();
                if (!params) return;
                this.tableConfig.loading = true;
                const res = await apiDistributorStockProduct(params);
                this.tableConfig.data = res.list.map((item) => {
                    const avgColumns = [];
                    for (let arr of Object.entries(item?.avgStock || {})) {
                        item[`stock${arr[0]}`] = arr[1];
                        avgColumns.push(renderColumnsDynamics(arr[0], 'stock'));
                    }
                    for (let arr of Object.entries(item?.avgOut || {})) {
                        item[`out${arr[0]}`] = arr[1];
                        avgColumns.push(renderColumnsDynamics(arr[0], 'out'));
                    }
                    const len = this.tableConfig.columns.length - 3;
                    this.tableConfig.columns.splice(3, len, ...avgColumns);
                    return item;
                });
                this.tableConfig.total = Number(res.total);
                this.tableConfig.loading = false;
                this.tableConfig.noDataText = '亲，没有找到相关记录哦！~';
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        // change-页码
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        // change-页数
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.$refs['tableFormNewRef'].resetForm();
            this.isSelectAll = false;
            this.isIndeterminate = true;
            this.averageStocCheckedList = averageStockDefaultSelect;
            this.averageOutboundCheckedList = averageStockDefaultSelect;
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        // 导出
        async onExport() {
            try {
                const params = this.getParams();
                if (!params) return;
                this.loadings.export = true;
                await apiDistributorStockProductExport(params);
                this.$Modal.confirm({
                    title: '提示',
                    content: `您好！“${this.$route.meta.title}”正在导出，请稍后在“导出列表”模块中查看，谢谢！`,
                    onOk: () => {},
                });
                this.loadings.export = false;
            } catch (error) {
                this.loadings.export = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.wi {
    .wi-icon {
        position: relative;
        top: 2px;
    }
}
/deep/ .ivu-table-header {
    .avg-stock-header {
        background-color: #e9f4fa;
    }
    .avg-out-header {
        background-color: #f4faea;
    }
}
</style>
