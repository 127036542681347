import { productNameNormalColumn } from '@/components/tablePage/common/normalColumn';

// 库存明细-筛选条件
const stockDetailFormList = [
    { type: 'select', label: '仓库', prop: 'warehouseIdList', multiple: true, options: [], loading: false },
    {
        type: 'select',
        label: '品牌',
        prop: 'brandIdList',
        multiple: true,
        filterable: true,
        options: [],
        loading: false,
    },
    {
        type: 'select',
        label: '花纹',
        prop: 'figureNameList',
        multiple: true,
        filterable: true,
        options: [],
        loading: false,
    },
    {
        type: 'select',
        label: '商品',
        prop: 'productIdList',
        multiple: true,
        filterable: true,
        options: [],
        loading: false,
    },
    {
        type: 'select',
        label: '零库存商品',
        prop: 'showZero',
        clearable: false,
        options: [
            { label: '展示', value: 1 },
            { label: '不展示', value: 0 },
        ],
        value: 1,
    },
];

// 平均库存-默认选中
const averageStockDefaultSelect = [30];
// 平均出库-默认选中
const averageOutboundDefaultSelect = [30];

// 库存明细-表头
const stockDetailColumns = [
    { ...productNameNormalColumn(), minWidth: 320, fixed: 'left' },
    {
        title: '条码库存',
        minWidth: 100,
        render: (h, p) => {
            return h('div', p.row.barcodeStockQuantity === null ? '-' : p.row.barcodeStockQuantity);
        },
    },
    {
        title: '盘存库存',
        minWidth: 100,
        render: (h, p) => {
            return h('div', p.row.stockQuantity === null ? '-' : p.row.stockQuantity);
        },
    },
];
// 平均库存/出库 map
const avgMap = {
    stock: { name: '库存', className: 'avg-stock-header' },
    out: { name: '出库', className: 'avg-out-header' },
};
// 渲染动态列 day:天 avgType:stock平均库存 out平均出库
const renderColumnsDynamics = (day, avgType) => {
    const data = avgMap[avgType];
    return {
        key: `${avgType}${day}`,
        minWidth: 100,
        className: data.className,
        // eslint-disable-next-line
        renderHeader: (h, p) => {
            return h('div', { style: { whiteSpace: 'pre-wrap' } }, `近${day}天\n平均${data.name}`);
        },
    };
};

export {
    stockDetailFormList,
    averageStockDefaultSelect,
    averageOutboundDefaultSelect,
    stockDetailColumns,
    renderColumnsDynamics,
};
