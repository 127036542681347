<template>
    <div v-loading="loading || disabled" class="ae">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card title="基本信息" :bordered="false" dis-hover class="ivu-mt">
            <Form :label-width="labelWidth" :label-position="labelPosition" ref="form" :model="form" :rules="rules">
                <Row :gutter="24">
                    <Col v-bind="grid">
                        <FormItem label="用户姓名：" prop="realName">
                            <Input v-if="mode == '2'" v-model="form.realName" :maxlength="16" />
                            <span v-else>{{ form.realName }}</span>
                        </FormItem>
                    </Col>
                    <Col v-bind="grid">
                        <FormItem label="登录账号：" prop="userName">
                            <Input v-if="mode == '2'" v-model="form.userName" :maxlength="16" />
                            <span v-else>{{ form.userName }}</span>
                        </FormItem>
                    </Col>
                    <Col v-bind="grid">
                        <FormItem label="手机号：" prop="mobile">
                            <Input v-if="mode == '2'" v-model="form.mobile" />
                            <span v-else>{{ form.mobile }}</span>
                        </FormItem>
                    </Col>

                    <Col v-bind="grid">
                        <FormItem label="E-mail：" prop="email">
                            <Input v-if="mode == '2'" v-model="form.email" />
                            <span v-else>{{ form.email }}</span>
                        </FormItem>
                    </Col>
                    <Col v-bind="grid">
                        <div class="ae-itemTwo">
                            <FormItem label="PDA扫码权限：" prop="isPda">
                                <checkbox v-model="form.isPda" v-if="mode == '2'"></checkbox>
                                <span v-else>{{ form.isPda ? '有' : '无' }}</span>
                            </FormItem>
                            <FormItem label="仓库：" prop="warehouseId" class="ae-warehouse" v-if="form.isPda">
                                <Select v-if="mode == '2'" v-model="form.warehouseId" style="width: 100%" clearable>
                                    <Option v-for="item in warehouseList" :value="item.id" :key="item.id">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                                <span v-else>{{ form.warehouseId ? form.warehouseName : '' }}</span>
                            </FormItem>
                        </div>
                    </Col>
                    <Col v-bind="grid">
                        <FormItem label="说明：">
                            <Input
                                :disabled="mode == '1'"
                                v-model="form.remark"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 3 }"
                                :maxlength="30"
                                placeholder="请输入说明信息..."
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Card title="角色" dis-hover :border="false" class="ivu-mt">
            <template v-if="mode == 1">
                <span class="ivu-mr" v-for="(item, index) in roleDTOS" :key="index">{{ item.name }}</span>
            </template>
            <CheckboxGroup v-model="form.roleIds" v-else>
                <Checkbox class="ivu-mr" :label="item.id" v-for="item in roleList" :key="item.id">{{
                    item.name
                }}</Checkbox>
            </CheckboxGroup>
        </Card>
        <FooterToolbar v-if="mode == '2'">
            <Button type="primary" :disabled="disabled" @click="onSave">保存</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {
    SettingAccountInfo,
    SettingAccountRole,
    SettingSaveAccount,
    apiDistributorSystemUserWarehouseList,
} from '@/api/setting';
import { MOBILE_REG, transPathToName, jumpPage } from '@/libs/util';

export default {
    name: 'setting-account-edit',
    data() {
        const accountNameChech = (rule, value, callback) => {
            if (!value) {
                callback(new Error(`请输入登录账号`));
            } else if (value.trim().includes('_')) {
                callback(new Error(`不能有下划线_`));
            } else {
                callback();
            }
        };
        const telCheck = (rule, value, callback) => {
            if (!value) {
                callback(new Error(`请输入手机号码`));
            } else if (!MOBILE_REG.test(value.trim())) {
                callback(new Error(`手机号码格式不正确`));
            } else {
                callback();
            }
        };
        const emailCheck = (rule, value, callback) => {
            let reg =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (!value) {
                callback(new Error(`请输入邮箱`));
            } else if (!reg.test(value.trim())) {
                callback(new Error(`邮箱格式不正确`));
            } else {
                callback();
            }
        };
        return {
            id: this.$route.query.id || null,
            mode: this.$route.query.mode || null,
            title: this.$route.query.id ? (this.$route.query.mode === 1 ? '账号详情' : '编辑账号') : '新增账号',
            loading: false,
            roleDTOS: [], // 编辑时后端返回的已选择的角色
            disabled: false,
            rules: {
                realName: [{ required: true, message: '请输入用户姓名', trigger: 'change' }],
                userName: [{ required: true, validator: accountNameChech, trigger: 'change' }],
                mobile: [{ required: true, validator: telCheck, trigger: 'change' }],
                email: [{ required: true, validator: emailCheck, trigger: 'change' }],
                warehouseId: [{ required: true, message: '请选择仓库', trigger: 'change' }],
            },
            grid: {
                xl: 8,
                lg: 8,
                md: 12,
                sm: 24,
                xs: 24,
            },
            form: {
                realName: '',
                userName: '',
                mobile: '',
                email: '',
                isPda: false,
                warehouseId: null,
                remark: '',
                roleIds: [],
            },
            roleList: [],
            warehouseList: [],
        };
    },
    mounted() {
        this.getWarehouseList();
        if (this.mode === 1) {
            // 详情
            this.getData();
        } else {
            // 新增、编辑
            this.initData();
        }
    },
    methods: {
        // 获取分仓列表
        async getWarehouseList() {
            const res = await apiDistributorSystemUserWarehouseList();
            console.log(11, res);
            this.warehouseList = res.map((item) => {
                let _item = {
                    ...item,
                    label: `${item.sn} 丨 ${item.name}`,
                };
                return _item;
            });
        },
        getData() {
            if (!this.id || this.loading) return;
            this.loading = true;
            this.disabled = true;
            SettingAccountInfo(this.id)
                .then((data) => {
                    this.roleDTOS = (data.roleDTOS || []).map((item) => {
                        return {
                            name: item.name,
                        };
                    });
                    this.form.realName = data.realName;
                    this.form.userName = data.userName;
                    this.form.mobile = data.mobile;
                    this.form.email = data.email;
                    this.form.remark = data.remark;
                    this.form.isPda = data.isPda;
                    this.form.warehouseId = data.warehouseId;
                    this.form.warehouseName = data.warehouseName;
                    this.form.roleIds = (data.roleDTOS || []).map((item) => item.id);
                    this.loading = false;
                    this.disabled = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.disabled = false;
                });
        },
        async initData() {
            await this.getRole();
            if (this.id) await this.getData();
        },
        getRole() {
            SettingAccountRole().then((data) => {
                this.roleList = (data || []).map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                    };
                });
            });
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/setting/account/list' });
        },
        onSave() {
            if (this.disabled) return;
            if (!this.form.roleIds.length) {
                this.$Notice.error({
                    title: '请选择角色',
                });
                return;
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = {
                        realName: this.form.realName,
                        userName: this.form.userName,
                        mobile: this.form.mobile,
                        email: this.form.email,
                        isPda: this.form.isPda,
                        warehouseId: this.form.warehouseId,
                        remark: this.form.remark,
                        roleIds: this.form.roleIds,
                    };
                    if (this.id) {
                        param.id = this.id;
                    }
                    if (!param.isPda) {
                        param.warehouseId = null;
                    }
                    this.disabled = true;
                    SettingSaveAccount(param)
                        .then(() => {
                            this.$Notice.success({
                                title: `${this.id ? '修改' : '新增'}成功`,
                            });
                            setTimeout(() => {
                                this.jumpPrevPage();
                                this.$bus.$emit('refresh');
                                this.disabled = false;
                            }, 100);
                        })
                        .catch(() => {
                            this.disabled = false;
                        });
                } else {
                    this.disabled = false;
                }
            });
        },
    },
    computed: {
        ...mapState('admin/layout', ['isMobile']),
        labelWidth() {
            return this.isMobile ? undefined : 140;
        },
        labelPosition() {
            return this.isMobile ? 'top' : 'right';
        },
    },
};
</script>

<style lang="less" scoped>
.ae {
    .ae-itemTwo {
        display: flex;
        .ae-warehouse {
            width: 100%;
            flex: 1;
            /deep/ .ivu-form-item-label {
                width: 80px !important;
            }
            /deep/ .ivu-form-item-content {
                margin-left: 80px !important;
            }
        }
    }
}
</style>
