<!--
 * @Author: chengleilei
 * @Date: 2021-12-29 15:10:04
 * @Description: 2022之后销售任务量详情
-->
<template>
    <div class="pd" v-loading="loading">
        <PageHeader title="签署门店销售任务量详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Form :label-width="155" label-position="right">
                <Card title="门店签署信息" :bordered="false" dis-hover class="ivu-mt">
                    <Row :gutter="24">
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                            <p class="ivu-mt-16">门店名称：{{ signInfo.storeName || '--' }}</p>
                        </Col>
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" v-if="signInfo.zhilunStoreId">
                            <p class="ivu-mt-16">绑定知轮门店ID：{{ signInfo.zhilunStoreId }}</p>
                        </Col>
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" v-if="signInfo.bindingTime">
                            <p class="ivu-mt-16">绑定时间：{{ signInfo.bindingTime }}</p>
                        </Col>
                    </Row>
                    <Row :gutter="24" v-for="(item, index) in infos" :key="index">
                        <Divider plain></Divider>
                        <Col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                            <p class="ivu-mt-16">奖励档：{{ item.reward || '--' }}</p>
                            <p class="ivu-mt-16">合同有效期：{{ item.dateTime || '--' }}</p>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                            <a href="javascript:;" :title="item.fileName || '暂无合同'" @click="checkPdf(item)">
                                <Icon type="md-document" size="52" style="color: red" />
                                <span>{{ item.fileName || '--' }}</span>
                                <span class="ivu-ml-16" v-if="item.fileUrl">
                                    {{ item.fileUrl ? '点击查看' : '--' }}
                                </span>
                            </a>
                        </Col>
                    </Row>
                </Card>
                <Card
                    :title="`年度任务完成量 ${resloveTotal(
                        detail.totalFinishNum,
                        detail.totalTaskNum,
                    )} ${zcAndZhiLunFinish(detail.zcTotalFinishNum, detail.zhilunTotalFinishNum)}`"
                    :bordered="false"
                    dis-hover
                    class="ivu-mt"
                >
                    <div class="flex-row">
                        <Progress
                            :percent="resolvePercent(detail.totalFinishNum, detail.totalTaskNum)"
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.totalFinishNum || 0 }}</span>
                        <i>/{{ detail.totalTaskNum || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{ zcAndZhiLunFinish(detail.zcTotalFinishNum, detail.zhilunTotalFinishNum) }}
                        </span>
                    </div>
                </Card>
                <Card
                    v-if="year == 2025"
                    :title="`本季（第${detail.currentQuarter.quarterNum}季度）任务完成 ${resloveTotal(
                        detail.currentQuarter.totalFinish,
                        detail.currentQuarter.totalTask,
                    )} ${zcAndZhiLunFinish(
                        detail.currentQuarter.zcTotalFinish,
                        detail.currentQuarter.zhilunTotalFinish,
                    )}`"
                    :bordered="false"
                    dis-hover
                    class="ivu-mt"
                >
                    <div class="flex-row" v-show="detail.currentQuarter.totalTask">
                        <span class="left-title">季度</span>
                        <Progress
                            :percent="
                                resolvePercent(detail.currentQuarter.totalFinish, detail.currentQuarter.totalTask)
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.currentQuarter.totalFinish || 0 }}</span>
                        <i>/{{ detail.currentQuarter.totalTask || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcTotalFinish,
                                    detail.currentQuarter.zhilunTotalFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div v-if="!detail.currentQuarter.totalTask">- 暂无数据 -</div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.thirdMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.thirdMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.thirdMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.thirdMonth }}月</span>
                        <Progress
                            :percent="
                                resolvePercent(detail.currentQuarter.thirdFinish, detail.currentQuarter.thirdTask)
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <div
                            @click="onDetail(detail.currentQuarter.thirdMonth)"
                            :class="{ 'show-click': year >= 2025 }"
                        >
                            <span>{{ detail.currentQuarter.thirdFinish || 0 }}</span>
                            <i>/{{ detail.currentQuarter.thirdTask || 0 }}条</i>
                        </div>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcThirdFinish,
                                    detail.currentQuarter.zhilunThirdFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.secondMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.secondMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.secondMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.secondMonth }}月</span>
                        <Progress
                            :percent="
                                resolvePercent(detail.currentQuarter.secondFinish, detail.currentQuarter.secondTask)
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <div
                            @click="onDetail(detail.currentQuarter.secondMonth)"
                            :class="{ 'show-click': year >= 2025 }"
                        >
                            <span>{{ detail.currentQuarter.secondFinish || 0 }}</span>
                            <i>/{{ detail.currentQuarter.secondTask || 0 }}条</i>
                        </div>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcSecondFinish,
                                    detail.currentQuarter.zhilunSecondFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.firstMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.firstMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.firstMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.firstMonth }}月</span>
                        <Progress
                            :percent="
                                resolvePercent(detail.currentQuarter.firstFinish, detail.currentQuarter.firstTask)
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <div
                            @click="onDetail(detail.currentQuarter.firstMonth)"
                            :class="{ 'show-click': year >= 2025 }"
                        >
                            <span>{{ detail.currentQuarter.firstFinish || 0 }}</span>
                            <i>/{{ detail.currentQuarter.firstTask || 0 }}条</i>
                        </div>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcFirstFinish,
                                    detail.currentQuarter.zhilunFirstFinish,
                                )
                            }}
                        </span>
                    </div>
                </Card>
                <Card title="历史季度完成情况" :bordered="false" dis-hover class="ivu-mt">
                    <div class="ivu-mb" v-for="(item, index) in detail.quarterList" :key="index">
                        <h4>
                            {{
                                `第${item.quarterNum}季度 ${resloveTotal(
                                    item.totalFinish,
                                    item.totalTask,
                                )} ${zcAndZhiLunFinish(item.zcTotalFinish, item.zhilunTotalFinish)}`
                            }}
                        </h4>
                        <div class="flex-row ivu-mt">
                            <span class="left-title">季度</span>
                            <Progress
                                :percent="resolvePercent(item.totalFinish, item.totalTask)"
                                :stroke-width="15"
                                status="active"
                            />
                            <span>{{ item.totalFinish || 0 }}</span>
                            <i>/{{ item.totalTask || 0 }}条</i>
                            <span>{{ zcAndZhiLunFinish(item.zcTotalFinish, item.zhilunTotalFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.thirdMonth">
                            <span class="left-title">{{ item.thirdMonth }}月</span>
                            <Progress
                                :percent="resolvePercent(item.thirdFinish, item.thirdTask)"
                                :stroke-width="15"
                                status="active"
                            />
                            <div @click="onDetail(item.thirdMonth)" :class="{ 'show-click': year >= 2025 }">
                                <span>{{ item.thirdFinish || 0 }}</span>
                                <i>/{{ item.thirdTask || 0 }}条</i>
                            </div>
                            <span>{{ zcAndZhiLunFinish(item.zcThirdFinish, item.zhilunThirdFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.secondMonth">
                            <span class="left-title">{{ item.secondMonth }}月</span>
                            <Progress
                                :percent="resolvePercent(item.secondFinish, item.secondTask)"
                                :stroke-width="15"
                                status="active"
                            />
                            <div @click="onDetail(item.secondMonth)" :class="{ 'show-click': year >= 2025 }">
                                <span>{{ item.secondFinish || 0 }}</span>
                                <i>/{{ item.secondTask || 0 }}条</i>
                            </div>
                            <span>{{ zcAndZhiLunFinish(item.zcSecondFinish, item.zhilunSecondFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.firstMonth">
                            <span class="left-title">{{ item.firstMonth }}月</span>
                            <Progress
                                :percent="resolvePercent(item.firstFinish, item.firstTask)"
                                :stroke-width="15"
                                status="active"
                            />
                            <div @click="onDetail(item.firstMonth)" :class="{ 'show-click': year >= 2025 }">
                                <span>{{ item.firstFinish || 0 }}</span>
                                <i>/{{ item.firstTask || 0 }}条</i>
                            </div>
                            <span>{{ zcAndZhiLunFinish(item.zcFirstFinish, item.zhilunFirstFinish) }}</span>
                        </div>
                    </div>
                    <div v-if="!detail.quarterList.length">- 暂无数据 -</div>
                </Card>
            </Form>
        </Card>
    </div>
</template>

<script>
import { esignTaskCurrentDetail } from '@/api/statistics';
import { esignStoreAllDetail, esignStoreFile } from '@/api/esign';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            storeId: this.$route.query.storeId || '',
            year: this.$route.query.year,
            loading: false,
            detail: {
                currentMonth: { finishNum: 0, taskNum: 0 },
                currentQuarter: {},
                quarterList: [],
            },
            signInfo: {},
            infos: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 月度任务完成明细
        onDetail(month) {
            if (this.year < 2025) return;
            jumpPage({
                path: `/sub-vue2/statistics/taskVolume/monthlyTaskDetail`,
                params: {
                    storeId: this.storeId,
                    storeName: this.signInfo.storeName,
                    taskMonth: `${this.year}${month}`,
                },
            });
        },
        // 处理中策、知轮数据
        zcAndZhiLunFinish(zcFinishNum, zlFinisshNum) {
            if (this.signInfo.zhilunStoreId && this.year < 2024) {
                return `（中策${zcFinishNum || 0}，知轮${zlFinisshNum || 0}）`;
            } else {
                return '';
            }
        },
        // 处理百分比
        resolvePercent(finishNum = 0, taskNum = 1) {
            const rate = Number(finishNum / (taskNum || 1)) * 100;
            return rate > 100 ? 100 : Number(rate.toFixed(2));
        },
        // 处理总数/
        resloveTotal(totalFinishNum = 0, totalTaskNum = 0) {
            return `${totalFinishNum}/${totalTaskNum}`;
        },
        // 查看合同
        checkPdf(row) {
            if (row.fileUrl) {
                const url = `${window.location.origin}/pdf/view?url=${row.fileUrl}&title=${row.fileName}`;
                window.open(url, '_blank');
            }
        },
        // 获取任务量详情
        async getData() {
            if (!this.storeId) return;
            try {
                this.loading = true;
                const taskRes = await esignTaskCurrentDetail({ storeId: this.storeId, startYear: this.year });
                this.detail = taskRes || {
                    currentMonth: { finishNum: 0, taskNum: 0 },
                    currentQuarter: {},
                    quarterList: [],
                };
                const esignRes = await esignStoreAllDetail({ storeId: this.storeId, year: this.year });
                this.signInfo = esignRes;
                this.infos = esignRes.infos;
                if (esignRes?.infos.length > 0) {
                    this.getAllPdf(esignRes.infos).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
            }
        },
        // 获取合同详情
        getAllPdf(infos) {
            return new Promise((resolve, reject) => {
                let list = JSON.parse(JSON.stringify(infos));
                infos.map((item, index) => {
                    esignStoreFile({
                        flowId: item.flowId,
                    }).then(
                        (pdf) => {
                            if (pdf && pdf.docs.length > 0) {
                                this.$set(this.infos, index, {
                                    ...item,
                                    fileId: pdf.docs[0].fileId,
                                    fileName: pdf.docs[0].fileName,
                                    fileUrl: pdf.docs[0].fileUrl,
                                });
                            }
                            if (index === infos.length - 1) {
                                resolve(list);
                            }
                        },
                        () => {
                            reject(list);
                        },
                    );
                });
            });
        },
        // 返回
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/taskVolume/list' });
        },
    },
};
</script>

<style lang="less" scoped>
.flex-row {
    display: flex;
    flex-flow: row nowrap;

    .left-title {
        display: inline-block;
        width: 70px;
        text-align: right;
        margin-right: 24px;
    }

    .ivu-progress {
        flex: 0 1 40%;
        margin-right: 24px;
        white-space: nowrap;
    }
}
.show-click {
    color: #2d8cf0;
    cursor: pointer;
}
</style>
