<!--
 * @Author: chengleilei
 * @Date: 2024-12-24 09:33:18
 * @Description: 门店店检年度统计-Tab按年份
-->
<template>
    <Card :bordered="false" dis-hover>
        <TableFormNew ref="tableFormNewRef" :formList="formList" @onSubmit="onSubmit" @onReset="onReset">
            <Button class="ivu-ml-8" slot="extraBtn" type="error" @click="exportData">导出</Button>
        </TableFormNew>
        <TablePage :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
    </Card>
</template>

<script>
import TableFormNew from '@/components/tableFormNew';
import TablePage from '@/components/tablePage';
import { clearInvalidKey } from '@/libs/util.tool';
import {
    storeInspectionYear,
    storeInspectionYearExport,
    storeAssessmentStatisticsYear,
    storeAssessmentStatisticsYearExport,
} from '@/api/statistics';

export default {
    components: { TableFormNew, TablePage },
    props: {
        formList: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
        currentTab: {
            type: String,
            default: '2025',
        },
    },
    data() {
        return {
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [...this.columns],
                data: [],
                loading: false,
            },
        };
    },
    activated() {},
    methods: {
        // 获取参数
        getParams() {
            const formData = this.$refs['tableFormNewRef'].getFormData();
            const params = {
                current: this.tableConfig.current,
                size: this.tableConfig.size,
                year: this.currentTab,
                ...formData,
            };
            return clearInvalidKey(params);
        },
        // 获取列表数据
        async getData() {
            try {
                this.tableConfig.loading = true;
                const cmd = this.currentTab > 2021 ? storeInspectionYear : storeAssessmentStatisticsYear;
                const res = await cmd(this.getParams());
                this.tableConfig.total = Number(res.total);
                this.tableConfig.data = res.records.map((item) => {
                    switch (this.currentTab) {
                        case '2021':
                            item.itemDTOS.map((k) => {
                                const key = `m${k.month}`;
                                const value = k.score || '--';
                                item[key] = value;
                            });
                            break;
                        default:
                            break;
                    }
                    return item;
                });
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        // current-change
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        // size-change
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        // 导出
        async exportData() {
            try {
                const cmd = this.currentTab > 2021 ? storeInspectionYearExport : storeAssessmentStatisticsYearExport;
                this.$Message.loading({ content: '导出中...', duration: 0 });
                const res = await cmd(this.getParams());
                this.$Message.destroy();
                if (!res) {
                    this.$Message.warning('暂无数据');
                    return false;
                }
                let aLink = document.createElement('a');
                aLink.href = res || '';
                document.body.appendChild(aLink);
                aLink.click();
                document.body.removeChild(aLink);
                this.$Notice.success({
                    title: '导出成功',
                });
            } catch (error) {
                this.$Message.destroy();
            }
        },
    },
};
</script>
