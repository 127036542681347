import {
    storeNameNormalColumn,
    ownerNameNormalColumn,
    ownerPhoneNormalColumn,
} from '@/components/tablePage/common/normalColumn';
import { getLabelByValue, storeDecorationStatusDict } from '@/dicts';

// 公共筛选
const formListCommon = [
    { label: '装修类型', prop: 'buildStoreType', type: 'select', options: storeDecorationStatusDict },
    { label: '门店', prop: 'storeInfo', type: 'input', placeholder: '请输入门店名称/联系人/联系方式' },
];
// 2021年筛选
const formList2021 = [...formListCommon.slice(1)];
// 2022年筛选
const formList2022 = [...formListCommon.slice(1)];
// 2023年筛选
const formList2023 = [...formListCommon.slice(1)];
// 2024年筛选
const formList2024 = [...formListCommon.slice(1)];
// 2025年筛选
const formList2025 = [...formListCommon];

// 公共表头
const columnsCommon = [
    { ...storeNameNormalColumn(), fixed: 'left' },
    {
        title: '门店装修类型',
        minWidth: 120,
        render: (h, p) => {
            return [getLabelByValue(storeDecorationStatusDict, p.row.buildStoreType)];
        },
    },
    { title: '签约档次', key: 'signLevel', minWidth: 120 },
    ownerNameNormalColumn(),
    ownerPhoneNormalColumn(),
    { title: '合格/不合格', key: 'passOrFail', minWidth: 120 },
];
// 获取1-12月份表头
const getMonthHeader = () => {
    const arr = [];
    for (let i = 1; i <= 12; i++) {
        arr.push({
            title: `${i}月`,
            minWidth: 80,
            render: (h, p) => {
                return [p.row[`m${i}`] || '--'];
            },
        });
    }
    return arr;
};
// 月份表头
const columnsMonth = getMonthHeader();
// 获取1-4季度表头
const getQuarterHeader = () => {
    const arr = [];
    for (let i = 1; i <= 4; i++) {
        arr.push({
            title: `Q${i}`,
            minWidth: 80,
            render: (h, p) => {
                return [p.row[`q${i}`] || '--'];
            },
        });
    }
    return arr;
};
// 季度表头
const columnsQuarter = getQuarterHeader();
// 2021年表头
const columns2021 = [
    ...columnsCommon.slice(0, 1),
    ...columnsCommon.slice(2, 5),
    ...columnsMonth,
    {
        title: '月度店检总分',
        minWidth: 120,
        render: (h, p) => {
            return [p.row.monthTotalScore == null ? '-' : p.row.monthTotalScore];
        },
    },
    {
        title: '附加分',
        minWidth: 80,
        render: (h, p) => {
            return [p.row.propagandaTotalScore == null ? '-' : p.row.propagandaTotalScore];
        },
    },
    {
        title: '扣分',
        minWidth: 80,
        render: (h, p) => {
            return [p.row.deductionTotalScore == null ? '-' : p.row.deductionTotalScore];
        },
    },
    {
        title: '年度总分',
        minWidth: 100,
        render: (h, p) => {
            return [p.row.yearTotalScore == null ? '-' : p.row.yearTotalScore];
        },
    },
];
// 2022年表头
const columns2022 = [...columnsCommon.slice(0, 1), ...columnsCommon.slice(2, 5), ...columnsQuarter, ...columnsMonth];
// 2023年表头
const columns2023 = [...columnsCommon.slice(0, 1), ...columnsCommon.slice(2, 5), ...columnsQuarter, ...columnsMonth];
// 2024年表头
const columns2024 = [...columnsCommon.slice(0, 1), ...columnsCommon.slice(2, 5), ...columnsQuarter, ...columnsMonth];
// 2025年表头
const columns2025 = [...columnsCommon, ...columnsQuarter, ...columnsMonth];

export {
    formList2021,
    formList2022,
    formList2023,
    formList2024,
    formList2025,
    columns2021,
    columns2022,
    columns2023,
    columns2024,
    columns2025,
};
