<template>
    <div>
        <PageHeader title="门店扫码详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { taskDetail, taskDetailExport } from '@/api/statistics';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'FinishTaskNumDetail',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isChangePage: false,
            formList: [
                {
                    title: '商品名称',
                    key: 'productName',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '胎号',
                    width: '230px',
                    key: 'tyreCode',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '条码',
                    key: 'barcode',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
            ],
            searchParams: {
                provinceId: '',
                distributorName: '',
            },
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '商品名称', key: 'productName', align: 'center', minWidth: 220, fixed: 'left' },
                    { title: '胎号', key: 'tyreCode', align: 'center', minWidth: 90 },
                    { title: '条码', key: 'barcode', align: 'center', minWidth: 140 },
                    { title: '类型', key: '_type', minWidth: 80 },
                    { title: '扫码时间', key: 'scanTime', align: 'center', minWidth: 120 },
                    { title: '备注', key: 'remark', align: 'center', minWidth: 100 },
                ],
                data: [],
            },
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/store/rebate' });
        },
        getData() {
            this.list.loading = true;
            let scanTimeStr = this.$route.query.scanTimeStr;
            let storeId = this.$route.query.storeId;
            let distributorId = this.$route.query.distributorId;
            let params = {
                current: this.list.current,
                size: this.list.size,
                verifyMonth: scanTimeStr,
                storeId,
                distributorId,
            };
            // delete params.provinceId
            taskDetail(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                            _type: item.type || '-',
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let scanTimeStr = this.$route.query.scanTimeStr;
            let storeId = this.$route.query.storeId;
            let distributorId = this.$route.query.distributorId;
            let params = {
                verifyMonth: scanTimeStr,
                storeId,
                distributorId,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            // delete params.provinceId
            taskDetailExport(params).then(
                (res) => {
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');

                    // this.$Modal.success({
                    //     title: '「门店扫码返利」导出成功',
                    //     content: '请到 [ 导出列表页 ] 查看及下载'
                    // });
                },
                () => {
                    this.$Message.destroy();
                    this.$Message.warning({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        onSubmit() {
            this.list.current = 1;
            this.getData();
        },
        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
