import request from '@/plugins/request';

// 月度返利列表
export function MonthRebateList(data) {
    return request({
        url: '/gather/monthTimes',
        method: 'post',
        data,
    });
}

// 月度返利明细
export function MonthRebateListExpand(data) {
    return request({
        url: '/gather/monthTimesDetail',
        method: 'post',
        data,
    });
}

// 月度明细
export function MonthDeatail(data) {
    return request({
        url: '/gather/monthTimes/detail',
        method: 'post',
        data,
    });
}
// 返利明细导出
export function ExportDetail(data) {
    return request({
        url: '/gather/monthTimes/detail/export',
        method: 'post',
        data,
    });
}

// 扫码库存 列表
export function ScanStockList(data) {
    return request({
        url: '/gather/monthTimes/productCount',
        method: 'post',
        data,
    });
}
export function ScanStockOptions(data) {
    return request({
        url: '/gather/monthTimes/opetions',
        method: 'post',
        params: data,
    });
}
export function ExportScanStock(data) {
    return request({
        url: '/gather/monthTimes/export',
        method: 'post',
        data,
    });
}

/***********
 * 门店积分返利
 * **********/

// 门店积分返利列表
export function storeRebateList(data) {
    return request({
        url: '/verify/rebate/findPage',
        method: 'post',
        data,
    });
}

// 门店积分返利列表导出
export function storeRebateListExport(data) {
    return request({
        url: '/verify/rebate/export',
        method: 'post',
        data,
    });
}

// 门店返利已发放-批量
export function storeRebatePayMoney(data) {
    return request({
        url: '/verify/rebate/payMoney',
        method: 'post',
        data,
    });
}

// 门店积分返利详情
export function storeRebateDetail(data) {
    return request({
        url: '/rebate/detail/findPage',
        method: 'post',
        data,
    });
}

// 门店积分返利列表导出
export function storeRebateDetailExport(data) {
    return request({
        url: '/rebate/detail/export',
        method: 'post',
        data,
    });
}

// 门店积分明细
export function storeScoreDetailCheck(data) {
    return request({
        url: '/rebate/detail/findScorePage',
        method: 'post',
        data,
    });
}

// 门店积分明细导出
export function storeScoreDetailExport(data) {
    return request({
        url: '/rebate/detail/exportScore',
        method: 'post',
        data,
    });
}

// 门店积分明细导出
export function taskDetail(data) {
    return request({
        url: '/rebate/verify/findPage',
        method: 'post',
        data,
    });
}

// 门店积分明细导出
export function taskDetailExport(data) {
    return request({
        url: '/rebate/verify/export',
        method: 'post',
        data,
    });
}

// 2021签署门店销售任务量
export function esignTaskPage(data) {
    return request({
        url: '/esgin/task/queryTask',
        method: 'post',
        data,
    });
}

// 2021签署门店销售任务量导出
export function esignTaskExport(data) {
    return request({
        url: '/esgin/task/export',
        method: 'post',
        data,
    });
}

// 2021合同销售任务量详情
export function esignTaskDetail(data) {
    return request({
        url: '/esgin/task/findPage',
        method: 'post',
        data,
    });
}

// 2022签署门店销售任务量
export function esignTaskPageCurrent(data) {
    return request({
        url: '/esgin/task/queryTask/current',
        method: 'post',
        data,
    });
}

// 2022签署门店销售任务量导出
export function esignTaskCurrentExport(data) {
    return request({
        url: '/esgin/task/exportTask/current',
        method: 'post',
        data,
    });
}

// 2022合同销售任务量详情
export function esignTaskCurrentDetail(data) {
    return request({
        url: '/esgin/task/findPage/current',
        method: 'post',
        data,
    });
}

// 月度任务完成明细
export function apiMonthlyCompletionDetails(data) {
    return request({
        url: '/esgin/task/monthlyCompletionDetails',
        method: 'post',
        data,
    });
}

/* *******
 * 门店考核
 * *******/

// 店检列表查询-2021
export function storeAssessmentList(data) {
    return request({
        url: `/storeAssessment/listStoreAssessments`,
        method: 'post',
        data,
    });
}

// 店检列表查询-2022
export function storeInspectionList(data) {
    return request({
        url: `/inspection/list`,
        method: 'post',
        data,
    });
}

// 店检详情-2022
export function storeInspectionDetail(storeInspectionId) {
    return request({
        url: `/inspection/detail/${storeInspectionId}`,
        method: 'get',
    });
}

// 门店考核详情(月度考核、宣传活动)
export function storeAssessmentDetailOne(data) {
    return request({
        url: `/storeAssessment/storeAssessmentDetailOne`,
        method: 'post',
        params: data,
    });
}

// 门店考核详情(巡检扣分)
export function storeAssessmentDetailTwo(data) {
    return request({
        url: `/storeAssessment/storeAssessmentDetailTwo`,
        method: 'post',
        params: data,
    });
}

// 店检列表导出-2021
export function storeAssessmentListExport(data) {
    return request({
        url: `/storeAssessment/storeAssessmentListExport`,
        method: 'post',
        data,
    });
}

// 店检列表导出-2022
export function storeInspectionListExport(data) {
    return request({
        url: `/inspection/list/export`,
        method: 'post',
        data,
    });
}

// 门店考核统计查询
export function storeAssessmentStatistics(data) {
    return request({
        url: `/storeAssessment/storeAssessmentStatistics`,
        method: 'post',
        data,
    });
}

// 门店考核统计导出
export function storeAssessmentStatisticsExport(data) {
    return request({
        url: `/storeAssessment/storeAssessmentStatisticsExport`,
        method: 'post',
        data,
    });
}

// 月度考核审核
export function monthlyApplyAudit(data) {
    return request({
        url: `/storeAssessment/monthlyApplyAudit`,
        method: 'post',
        params: data,
    });
}

// 月度考核通过
export function monthlyApplyAuditPass(data) {
    return request({
        url: `/storeAssessment/monthlyApplyAuditPass`,
        method: 'post',
        params: data,
    });
}

// 宣传活动审核
export function propagandaAudit(data) {
    return request({
        url: `/storeAssessment/propagandaAudit`,
        method: 'post',
        params: data,
    });
}

// 宣传活动审核通过
export function propagandaAuditPass(data) {
    return request({
        url: `/storeAssessment/propagandaAuditPass`,
        method: 'post',
        params: data,
    });
}
/****
 * 年度统计表
 * **/
// 年度店检统计-2022
export function storeInspectionYear(data) {
    return request({
        url: `/inspection/year/list`,
        method: 'post',
        data,
    });
}

// 年度店检统计导出-2022
export function storeInspectionYearExport(data) {
    return request({
        url: `/inspection/year/export`,
        method: 'post',
        data,
    });
}

// 年度店检统计-2021
export function storeAssessmentStatisticsYear(data) {
    return request({
        url: `/storeAssessment/storeAssessmentStatistics`,
        method: 'post',
        data,
    });
}

// 年度店检统计导出-2021
export function storeAssessmentStatisticsYearExport(data) {
    return request({
        url: `/storeAssessment/storeAssessmentStatisticsExport`,
        method: 'post',
        data,
    });
}

/*
 门店线上支付统计
 */

// 月度门店线上支付统计
export function payStatistics(data) {
    return request({
        url: '/special/order/payStatistics',
        method: 'post',
        data,
    });
}

// 月度门店线上支付统计导出
export function payStatisticsExport(data) {
    return request({
        url: '/special/order/payStatisticsExport',
        method: 'post',
        data,
    });
}

// 月度门店线上支付统计明细
export function payDetail(data) {
    return request({
        url: '/special/order/payDetail',
        method: 'post',
        data,
    });
}

// 月度门店线上支付统计明细导出
export function payDetailExport(data) {
    return request({
        url: '/special/order/payDetailExport', // toDo 暂无
        method: 'post',
        data,
    });
}

// 特价订单详情
export function specialOrderDetail(data) {
    return request({
        url: `/special/order/detail`,
        method: 'post',
        params: data,
    });
}

// 线上退款明细
export function refundDetail(data) {
    return request({
        url: '/refund/specialOrder/detail',
        method: 'post',
        data,
    });
}

// 线上退款明细导出
export function refundDetailExport(data) {
    return request({
        url: '/refund/specialOrder/detail/export', // toDo 暂无
        method: 'post',
        data,
    });
}

// 门店新零售补贴
// 新零售补贴列表
export function subsidiesList(data) {
    return request({
        url: '/rent/subsidies/list',
        method: 'post',
        data,
    });
}

// 新零售补贴列表导出
export function subsidiesListExport(data) {
    return request({
        url: '/rent/subsidies/export',
        method: 'post',
        data,
    });
}

// 三包胎明细
export function getThreePackTireList(storeId) {
    return request({
        url: `/rent/subsidies/sanbaoDetail/${storeId}`,
        method: 'get',
    });
}

// 授信手续费列表查询
export function feeList(data) {
    return request({
        url: '/report/credit/fee/list',
        method: 'post',
        data,
    });
}

// 授信手续费列表导出
export function feeListExport(data) {
    return request({
        url: '/report/credit/fee/export',
        method: 'post',
        data,
    });
}

// 侠系列签约返利列表
export function xiaRebateList(data) {
    return request({
        url: `/report/xia/rebate/list`,
        method: 'post',
        data,
    });
}

// 侠系列签约返利列表导出
export function xiaRebateListExport(data) {
    return request({
        url: `/report/xia/rebate/export`,
        method: 'post',
        data,
    });
}

// 侠系列签约返利详情
export function xiaRebateDetail(data) {
    return request({
        url: `/report/xia/rebate/detail`,
        method: 'post',
        data,
    });
}

// 侠系列签约返利列表详情导出
export function xiaRebateDetailExport(data) {
    return request({
        url: `/report/xia/rebate/detail/export`,
        method: 'post',
        data,
    });
}

// 经销商扫码积分列表
export function scanScoreList(data) {
    return request({
        url: `/distributor/scan/score/list`,
        method: 'get',
        params: data,
    });
}

// 经销商扫码积分导出
export function scanScoreExport(data) {
    return request({
        url: `/distributor/scan/score/export`,
        method: 'get',
        params: data,
    });
}

// 【商品库存】 ==============================================================================
// 获取所有品牌
export function apiProductBrandAllList(data) {
    return request({
        url: `/product/brand/allList`,
        method: 'get',
        params: data,
    });
}

// 条件查询全部数据字典
export function apiProductDictionaryFind(data) {
    return request({
        url: `/product/dictionary/find`,
        method: 'get',
        params: data,
    });
}

// 商品分页查询
export function apiActivityFindProductList(data) {
    return request({
        url: `/activity/findProductList`,
        method: 'post',
        data,
    });
}

// 商品库存
export function apiDistributorStockProduct(data) {
    return request({
        url: `/distributor/stock/product`,
        method: 'post',
        data,
    });
}

// 商品库存导出
export function apiDistributorStockProductExport(data) {
    return request({
        url: `/distributor/stock/product/export`,
        method: 'post',
        data,
    });
}
