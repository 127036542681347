import request from '@/plugins/request';

// 账号管理 ======================================================================
// 管理员账号列表
export function SettingAccountList(data) {
    return request({
        url: '/distributor/system/user/list',
        method: 'post',
        data,
    });
}

// 管理员账号禁用启用
export function SettingAccountEnable(isEnabled, id) {
    let url = isEnabled ? '/distributor/system/user/disable/' + id : '/distributor/system/user/enable/' + id;
    return request({
        url: url,
        method: 'post',
    });
}

// 管理员账号重置短信
export function SettingAccountReset(id) {
    let url = '/distributor/system/user/reset/' + id;
    return request({
        url: url,
        method: 'post',
    });
}

// 管理员账号详情
export function SettingAccountInfo(id) {
    let url = '/distributor/system/user/info/' + id;
    return request({
        url: url,
        method: 'get',
    });
}

// 查询分仓列表
export function apiDistributorSystemUserWarehouseList() {
    return request({
        url: '/distributor/system/user/warehouse/list',
        method: 'get',
    });
}

// 角色管理 ======================================================================
// 管理员获取所有的角色
export function SettingAccountRole() {
    return request({
        url: '/distributor/system/user/getRoles',
        method: 'get',
    });
}

// 管理员保存
export function SettingSaveAccount(data) {
    return request({
        url: '/distributor/system/user/saveOrUpdate',
        method: 'post',
        data,
    });
}

// 角色列表
export function SettingRoleList(data) {
    return request({
        url: '/distributor/system/role/list',
        method: 'post',
        data,
    });
}

// 角色所有权限获取
export function SettingMenuList() {
    return request({
        url: '/distributor/system/role/menus',
        method: 'post',
    });
}

// 角色详情
export function SettingRoleInfo(id) {
    return request({
        url: '/distributor/system/role/info/' + id,
        method: 'get',
    });
}

// 角色删除
export function SettingRoleDel(id) {
    return request({
        url: '/distributor/system/role/' + id,
        method: 'delete',
    });
}

// 角色保存
export function SettingRoleSave(data) {
    return request({
        url: '/distributor/system/role/saveOrUpdate',
        method: 'post',
        data,
    });
}
/***** 经销商e签宝 S *****/
// 创建企业
export function createCompany(data) {
    return request({
        url: '/esgin/account/createCompany',
        method: 'post',
        data,
    });
}

// 查询企业
export function queryCompany(data) {
    return request({
        url: '/esgin/account/queryCompany',
        method: 'post',
        data,
    });
}
/***** 经销商e签宝 E *****/

// 修改账户 密码
export function ResetPassword(data) {
    return request({
        url: '/distributor/system/user/updatePwd',
        method: 'post',
        data,
    });
}
