<!--
* @Author: zhangping
* @Date: 2024-12-17 09:00:00
* @Description: 签署门店销售任务量-签署门店销售任务量详情-月度任务完成明细
-->

<template>
    <div class="mtd">
        <PageHeader title="月度任务完成明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <div class="mb-10">{{ storeName }}</div>
            <TablePage :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import TablePage from '@/components/tablePage';
import { monthlyTaskDetailColumns } from './const';
import { apiMonthlyCompletionDetails } from '@/api/statistics';
export default {
    components: { TablePage },
    data() {
        return {
            storeId: this.$route.query.storeId,
            storeName: this.$route.query.storeName,
            taskMonth: this.$route.query.taskMonth,
            tableConfig: {
                loading: false,
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: monthlyTaskDetailColumns,
                data: [],
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        jumpPrevPage() {
            this.close({ tagName: this.$route.fullPath });
            this.$router.back();
        },
        async getData() {
            try {
                this.tableConfig.loading = true;
                const { current, size } = this.tableConfig;
                const params = { storeId: this.storeId, taskMonth: this.taskMonth, current, size };
                const res = await apiMonthlyCompletionDetails(params);
                this.tableConfig.data = (res.list || []).map((item) => {
                    item._taskQuantity = item.taskQuantity > 0 ? `+${item.taskQuantity}` : item.taskQuantity;
                    return item;
                });
                this.tableConfig.total = Number(res.total);
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped></style>
