<!--
* @Author: zhangping
* @Date: 2024-12-20 09:00:00
* @Description: 门店店检统计
-->

<template>
    <div>
        <PageHeader title="门店店检统计" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="activeTab" :animated="true" @on-click="changeTab" style="margin-top: -12px">
                <TabPane v-for="item in tabList" :key="item.name" :label="item.label" :name="item.name">
                    <YearTab
                        :activeTab="activeTab"
                        :year="item.name"
                        :ref="item.name + 'yearTabRef'"
                        :formList="item.formList"
                        :columns="item.columns"
                    />
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>

<script>
import YearTab from './components/YearTab';
import {
    formList2021,
    formList2022,
    formList2023,
    formList2024,
    formList2025,
    tableColumnCommon,
    tableColumn2021,
    tableColumn2025,
} from './const';
export default {
    components: { YearTab },
    data() {
        return {
            activeTab: '2025',
            tabList: [
                { label: '2025年', name: '2025', formList: formList2025, columns: tableColumn2025 },
                { label: '2024年', name: '2024', formList: formList2024, columns: tableColumnCommon },
                { label: '2023年', name: '2023', formList: formList2023, columns: tableColumnCommon },
                { label: '2022年', name: '2022', formList: formList2022, columns: tableColumnCommon },
                { label: '2021年', name: '2021', formList: formList2021, columns: tableColumn2021 },
            ],
        };
    },
    methods: {
        // 切换tab
        changeTab(e) {
            if (e === this.activeTab) {
                return false;
            }
            this.activeTab = e;
        },
    },
};
</script>

<style lang="less" scoped></style>
