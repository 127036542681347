<!--
 * @Author: chengleilei
 * @Date: 2021-12-24 15:11:48
 * @Description: 2021销售任务量详情
-->
<template>
    <div class="pd" v-loading="loading">
        <PageHeader title="签署门店销售任务量详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Form :label-width="155" label-position="right">
                <Card title="门店签署信息" :bordered="false" dis-hover class="ivu-mt">
                    <Row :gutter="24">
                        <Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                            <p class="ivu-mt-16">门店名称：{{ signInfo.storeName || '--' }}</p>
                        </Col>
                    </Row>

                    <Row :gutter="24" v-for="(item, index) in infos" :key="index">
                        <Divider plain></Divider>
                        <Col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                            <p class="ivu-mt-16">奖励档：{{ item.reward || '--' }}</p>
                            <p class="ivu-mt-16">合同有效期：{{ item.dateTime || '--' }}</p>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                            <a href="javascript:;" :title="item.fileName || '暂无合同'" @click="checkPdf(item)">
                                <Icon type="md-document" size="52" style="color: red" />
                                <span>{{ item.fileName || '--' }}</span>
                                <span class="ivu-ml-16" v-if="item.fileUrl">{{
                                    item.fileUrl ? '点击查看' : '--'
                                }}</span>
                            </a>
                        </Col>
                    </Row>
                </Card>
                <Card
                    :title="`年度任务完成量 ${resloveTotal(detail.totalFinishNum, detail.totalTaskNum)}`"
                    :bordered="false"
                    dis-hover
                    class="ivu-mt"
                >
                    <div class="flex-row">
                        <Progress
                            :percent="resolvePercent(detail.totalFinishNum, detail.totalTaskNum)"
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.totalFinishNum || 0 }}</span>
                        <i>/{{ detail.totalTaskNum || 0 }}条</i>
                    </div>
                </Card>
                <Card title="历史月任务完成量" :bordered="false" dis-hover>
                    <Row :gutter="24" v-if="detail && detail.records && detail.records.length > 0">
                        <Col
                            :xl="12"
                            :lg="12"
                            :md="12"
                            :sm="12"
                            :xs="12"
                            class="ivu-mb-8 ivu-mt-8"
                            v-for="(item, index) in detail.records"
                            :key="index"
                        >
                            <Row>
                                <p>{{ item.taskMonth }}月销售量 {{ item.finishNum }}/{{ item.taskNum }}</p>
                            </Row>
                            <Row>
                                <Col :xl="20" :lg="18" :md="18" :sm="18" :xs="18">
                                    <Progress
                                        :percent="resolvePercent(item.finishNum, item.taskNum)"
                                        :stroke-width="15"
                                        status="active"
                                    />
                                </Col>
                                <Col :xl="4" :lg="6" :md="6" :sm="6" :xs="6">
                                    <span>{{ item.taskNum }}条</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
                <Row v-if="!detail || !detail.records || detail.records.length <= 0">- 暂无数据 -</Row>
            </Form>
        </Card>
    </div>
</template>

<script>
import { esignTaskDetail } from '@/api/statistics';
import { esignStoreAllDetail, esignStoreFile } from '@/api/esign';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            storeId: this.$route.query.storeId || '',
            year: this.$route.query.year,
            loading: false,
            detail: {},
            signInfo: {},
            infos: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 处理百分比
        resolvePercent(finishNum = 0, taskNum = 1) {
            const rate = Number(finishNum / (taskNum || 1)) * 100;
            return rate > 100 ? 100 : Number(rate.toFixed(2));
        },
        // 处理总数/
        resloveTotal(totalFinishNum = 0, totalTaskNum = 0) {
            return `${totalFinishNum}/${totalTaskNum}`;
        },
        // 查看合同
        checkPdf(item) {
            if (item.fileUrl) {
                const url = `${window.location.origin}/pdf/view?url=${item.fileUrl}&title=${item.fileName}`;
                window.open(url, '_blank');
            }
        },
        // 获取任务量详情
        async getData() {
            if (!this.storeId) return;
            try {
                this.loading = true;
                const taskRes = await esignTaskDetail({ storeId: this.storeId, startYear: this.year });
                this.detail = taskRes || {};
                const esignRes = await esignStoreAllDetail({ storeId: this.storeId, year: this.year });
                this.signInfo = esignRes;
                this.infos = esignRes.infos;
                if (esignRes?.infos.length > 0) {
                    this.getAllPdf(esignRes.infos).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
            }
        },
        // 获取合同详情
        getAllPdf(infos) {
            return new Promise((resolve, reject) => {
                let list = JSON.parse(JSON.stringify(infos));
                infos.map((item, index) => {
                    esignStoreFile({
                        flowId: item.flowId,
                    }).then(
                        (pdf) => {
                            if (pdf && pdf.docs.length > 0) {
                                this.$set(this.infos, index, {
                                    ...item,
                                    fileId: pdf.docs[0].fileId,
                                    fileName: pdf.docs[0].fileName,
                                    fileUrl: pdf.docs[0].fileUrl,
                                });
                            }
                            if (index === infos.length - 1) {
                                resolve(list);
                            }
                        },
                        () => {
                            reject(list);
                        },
                    );
                });
            });
        },
        // 返回
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/taskVolume/list' });
        },
    },
};
</script>

<style lang="less" scoped>
.flex-row {
    display: flex;
    flex-flow: row nowrap;
    .left-title {
        display: inline-block;
        width: 70px;
        text-align: right;
        margin-right: 24px;
    }
    .ivu-progress {
        flex: 0 1 40%;
        margin-right: 24px;
        white-space: nowrap;
    }
}
</style>
