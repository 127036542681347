<!--
 * @Author: chengleilei
 * @Date: 2024-12-17 14:26:20
 * @Description: 签署门店销售任务量-Tab按年份
-->
<template>
    <Card :bordered="false" dis-hover>
        <TableFormNew ref="tableFormNewRef" :formList="formList" @onSubmit="onSubmit" @onReset="onReset">
            <Button class="ivu-ml-8" slot="extraBtn" type="error" @click="exportData">导出</Button>
        </TableFormNew>
        <TablePage :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
    </Card>
</template>
<script>
import TableFormNew from '@/components/tableFormNew';
import TablePage from '@/components/tablePage';
import { clearInvalidKey } from '@/libs/util.tool';
import { esignTaskPage, esignTaskPageCurrent, esignTaskExport, esignTaskCurrentExport } from '@/api/statistics';
import { AllSeriesList, taskEsignLevel } from '@/api/common';
import { renderButton, jumpPage } from '@/libs/util';

export default {
    components: { TableFormNew, TablePage },
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        currentTab: {
            type: String,
            default: '2025',
        },
    },
    data() {
        return {
            formList: [
                { label: '合同名称', prop: 'contractName', type: 'input' },
                {
                    label: '门店信息',
                    prop: 'storeName',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式/登录账号',
                },
                {
                    type: 'cascader',
                    label: '主品牌/档次',
                    prop: 'brandLevel',
                    options: [],
                    loadData: this.loadData,
                },
            ],
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    ...this.columns,
                    {
                        title: '操作',
                        key: 'oprate',
                        width: 100,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.onDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    activated() {
        this.getBrand();
    },
    methods: {
        // 详情
        onDetail(row) {
            jumpPage({
                path: `/sub-vue2/statistics/taskVolume/${this.currentTab > 2021 ? 'detailCurrent' : 'detail'}`,
                params: {
                    storeId: row.storeId,
                    year: this.currentTab,
                },
            });
        },
        // 加载档次
        async loadData(item, callback) {
            item.loading = true;
            try {
                const res = await taskEsignLevel(item.value, this.currentTab);
                item.children = res.map((k) => {
                    return {
                        value: k.id,
                        label: k.levelName,
                    };
                });
                item.loading = false;
                callback();
            } catch (e) {
                item.loading = false;
                callback();
            }
        },
        // 获取主品牌
        async getBrand() {
            const res = await AllSeriesList();
            this.formList[2].options = res.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    children: [],
                    loading: false,
                };
            });
        },
        // 获取参数
        getParams() {
            const formData = this.$refs['tableFormNewRef'].getFormData();
            formData.deptId = formData?.dept?.[0];
            formData.regionId = formData?.dept?.[1];
            if (formData?.brandLevel?.length) {
                formData.brandMainId = formData?.brandLevel?.[0];
                formData.gradeId = formData?.brandLevel?.[1];
                delete formData.brandLevel;
            }
            const params = {
                current: this.tableConfig.current,
                size: this.tableConfig.size,
                startYear: this.currentTab,
                ...formData,
            };
            return clearInvalidKey(params);
        },
        // 获取列表数据
        async getData() {
            try {
                this.tableConfig.loading = true;
                const cmd = this.currentTab > 2021 ? esignTaskPageCurrent : esignTaskPage;
                const res = await cmd(this.getParams());
                this.tableConfig.total = Number(res.total);
                this.tableConfig.data = res.records;
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        // current-change
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        // size-change
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        // 导出
        async exportData() {
            try {
                const cmd = this.currentTab > 2021 ? esignTaskCurrentExport : esignTaskExport;
                this.$Message.loading({ content: '导出中...', duration: 0 });
                const res = await cmd(this.getParams());
                this.$Message.destroy();
                // > 2021年，导出列表中查看
                if (this.currentTab > 2021) {
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您好！“${this.$route.meta.title}”正在导出，请稍后在“导出列表”模块中查看，谢谢！`,
                        onOk: () => {
                            console.log('export');
                        },
                    });
                    return;
                }
                // 2021年，下载excel
                if (!res) {
                    this.$Message.warning('暂无数据');
                    return false;
                }
                let aLink = document.createElement('a');
                aLink.href = res || '';
                document.body.appendChild(aLink);
                aLink.click();
                document.body.removeChild(aLink);
                this.$Message.success('导出成功');
            } catch (error) {
                this.$Message.destroy();
                this.$Message.warning({
                    title: '导出失败 ！',
                });
            }
        },
    },
};
</script>
