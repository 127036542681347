/*
 * @Author: chengleilei
 * @Date: 2023-12-07 16:21:52
 * @Description:
 */
import {
    storeNameNormalColumn,
    ownerNameNormalColumn,
    ownerPhoneNormalColumn,
    numberNormalColumn,
    dateNormalColumn,
    remarkNormalColumn,
} from '@/components/tablePage/common/normalColumn';

const indexColumns = [
    { ...storeNameNormalColumn(), fixed: 'left' },
    ownerNameNormalColumn(),
    ownerPhoneNormalColumn(),
    { title: '合同名称', key: 'contractName', minWidth: 160 },
    { title: '合同生效时间', key: 'contractStartTime', minWidth: 120 },
    { title: '档次', key: 'levelName', minWidth: 120 },
    { title: '主营品牌', key: 'brandMainName', minWidth: 160 },
    {
        title: '知轮签约',
        minWidth: 100,
        render: (h, p) => {
            return h('span', p.row.zhilunSignStr);
        },
    },
    {
        title: '月度完成量/月度总任务量',
        minWidth: 200,
        render: (h, { row }) => {
            if (!row.zhilunSign || !['2022', '2023'].includes(row.startYear))
                return h('div', `${row.monthNumStr || '--'}`);
            return h('div', [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcMonthFinishNum}，知轮 ${row.zhilunMonthFinishNum}`,
                        },
                    },
                    [h('span', `${row.monthNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '月度完成率',
        minWidth: 120,
        render: (h, p) => {
            return h('div', `${p.row.monthRate || '--'}`);
        },
    },
    {
        title: '季度完成量/季度总任务量',
        minWidth: 200,
        render: (h, { row }) => {
            if (!row.zhilunSign || !['2022', '2023'].includes(row.startYear))
                return h('div', `${row.quarterFinishNumStr || '--'}`);
            return h('div', [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcQuarterFinishNum}，知轮 ${row.zhilunQuarterFinishNum}`,
                        },
                    },
                    [h('span', `${row.quarterFinishNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '季度完成率',
        minWidth: 120,
        render: (h, p) => {
            return h('div', `${p.row.quarterRate || '--'}`);
        },
    },
    {
        title: '年度完成量/年度总任务量',
        minWidth: 200,
        render: (h, { row }) => {
            if (!row.zhilunSign || !['2022', '2023'].includes(row.startYear))
                return h('div', `${row.yearNumStr || '--'}`);
            return h('div', [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcYearFinishNum}，知轮 ${row.zhilunYearFinishNum}`,
                        },
                    },
                    [h('span', `${row.yearNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '年度完成率',
        minWidth: 120,
        render: (h, p) => {
            return h('div', `${p.row.yearRate || '--'}`);
        },
    },
];

// 月度任务完成明细表头
const monthlyTaskDetailColumns = [
    { title: '商品名称', key: 'productName', minWidth: 220 },
    { title: '系列', key: 'series', minWidth: 100 },
    { title: '品牌', key: 'brandName', minWidth: 160 },
    { ...numberNormalColumn('尺寸', 'size'), minWidth: 80 },
    { title: '胎号', key: 'tireCode', minWidth: 120 },
    { title: '任务量', key: '_taskQuantity', minWidth: 100 },
    { title: '类型', key: 'type', minWidth: 100 },
    dateNormalColumn('扫码时间', 'scanTime'),
    remarkNormalColumn(null, 'remark'),
];

export { indexColumns, monthlyTaskDetailColumns };
