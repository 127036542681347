import { monthDateItem } from '@/components/tableFormNew/common/dateItem';
import { storeInspectAuditStatusDict, storeDecorationStatusDict } from '@/dicts';
import {
    storeNameNormalColumn,
    ownerNameNormalColumn,
    ownerPhoneNormalColumn,
    remarkNormalColumn,
} from '@/components/tablePage/common/normalColumn';

// 公共的筛选条件
const formListCommon = [
    { label: '门店', prop: 'storeInfo', type: 'input', placeholder: '请输入门店名称/联系人/联系方式' },
    monthDateItem,
    { type: 'select', label: '状态', prop: 'status', options: storeInspectAuditStatusDict },
];
// 2021年筛选条件
const formList2021 = [
    { label: '门店', prop: 'keyword', type: 'input', placeholder: '请输入门店名称/联系人/联系方式' },
    { ...monthDateItem, prop: 'startDate', startDate: new Date(2021, 0, 1) },
    {
        type: 'select',
        label: '状态',
        prop: 'auditStatus',
        options: [
            { value: 0, label: '门店未提交' },
            { value: 1, label: '门店已提交' },
            { value: 2, label: '业务主管已审核' },
            { value: 3, label: '审核完成' },
        ],
    },
];
// 2022年筛选条件
const formList2022 = [
    ...formListCommon.slice(0, 1),
    {
        ...monthDateItem,
        prop: 'month',
        startDate: new Date(2022, 0, 1),
        options: {
            disabledDate(date) {
                if (!date) return true;
                return !(
                    date.valueOf() >= new Date(2021, 11, 31).valueOf() &&
                    date.valueOf() < new Date(2022, 11, 31).valueOf()
                );
            },
        },
    },
    ...formListCommon.slice(2),
];
// 2023年筛选条件
const formList2023 = [
    ...formListCommon.slice(0, 1),
    { ...monthDateItem, prop: 'month', startDate: new Date(2023, 0, 1) },
    ...formListCommon.slice(2),
];
// 2024年筛选条件
const formList2024 = [
    ...formListCommon.slice(0, 1),
    { ...monthDateItem, prop: 'month', startDate: new Date(2024, 0, 1) },
    ...formListCommon.slice(2),
];
// 2025年筛选条件
const formList2025 = [
    ...formListCommon.slice(0, 1),
    { ...monthDateItem, prop: 'month', startDate: new Date(2025, 0, 1) },
    ...formListCommon.slice(2),
    { type: 'select', label: '装修类型', prop: 'buildStoreType', options: storeDecorationStatusDict },
];

// 公共是表头-审核状态-映射
const auditStatusColorMapOne = {
    0: '#ed4014',
    1: '#f90',
    2: '#2d8cf0',
    3: '#19be6b',
};
const auditStatusColorMapTwo = {
    '': '#f90',
    1: '#ed4014',
    2: '#f90',
    3: '#2d8cf0',
    4: '#19be6b',
    5: '#ed4014',
};
// 公共的表头
const tableColumnCommon = [
    { ...storeNameNormalColumn(), fixed: 'left' },
    { title: '门店类型', key: 'typeName', minWidth: 120 },
    { title: '签约档次', key: 'signLevel', minWidth: 220 },
    ownerNameNormalColumn(),
    ownerPhoneNormalColumn(),
    { title: '月份', key: 'applyMonth', minWidth: 100 },
    { title: '提交时间', key: '_applyTime', minWidth: 180 },
    {
        title: '审核状态',
        key: 'auditStatus',
        minWidth: 160,
        fixed: 'right',
        render: (h, p) => {
            return h(
                'div',
                {
                    style: { color: auditStatusColorMapTwo[p.row.auditStatus] },
                },
                {
                    '': '-',
                    1: '门店未提交',
                    2: '门店已提交',
                    3: '业务主管已审核',
                    4: '审核完成',
                    5: '已驳回',
                }[p.row.auditStatus] || '-',
            );
        },
    },
    { title: '审核结果', key: '_channelManAuditResult', minWidth: 100, fixed: 'right' },
];
// 2021年表头
const tableColumn2021 = [
    ...tableColumnCommon.slice(0, 5),
    { title: '月份', key: 'month', minWidth: 100 },
    {
        title: '店检类型',
        key: 'type',
        minWidth: 170,
        fixed: 'right',
        render: (h, p) => {
            return h(
                'div',
                {
                    1: '月度店检',
                    2: '宣传路演活动',
                    3: '巡检扣分',
                }[p.row.type],
            );
        },
    },
    {
        title: '审核状态',
        key: 'auditStatus',
        minWidth: 120,
        render: (h, p) => {
            return h(
                'div',
                {
                    style: { color: auditStatusColorMapOne[p.row.auditStatus] },
                },
                {
                    0: '门店未提交',
                    1: '门店已提交',
                    2: '业务主管已审核',
                    3: '审核完成',
                }[p.row.auditStatus],
            );
        },
    },
    { title: '得分', key: 'score', minWidth: 140 },
    remarkNormalColumn(),
];
// 2025年表头
const tableColumn2025 = [
    ...tableColumnCommon.slice(0, 1),
    { title: '门店装修类型', key: '_buildStoreType', minWidth: 120 },
    ...tableColumnCommon.slice(2),
];

export {
    formList2021,
    formList2022,
    formList2023,
    formList2024,
    formList2025,
    tableColumnCommon,
    tableColumn2021,
    tableColumn2025,
};
