<!--
* @Author: zhangping
* @Date: 2024-12-20 09:00:00
* @Description: 门店店检统计-Tab按年份
-->

<template>
    <div>
        <TableFormNew ref="tableFormNewRef" :formList="formList" @onSubmit="onSubmit" @onReset="onReset">
            <Button class="ivu-ml-8" slot="extraBtn" type="error" @click="exportData">导出</Button>
        </TableFormNew>
        <TablePage :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />

        <StoreModal ref="StoreModal" @refreshList="getData" />
        <StoreModalCurrent ref="StoreModalCurrent" />
    </div>
</template>

<script>
import dayjs from 'dayjs';
import TableFormNew from '@/components/tableFormNew';
import TablePage from '@/components/tablePage';
import {
    storeInspectionList,
    storeInspectionListExport,
    storeAssessmentList,
    storeAssessmentListExport,
} from '@/api/statistics';
import { clearInvalidKey } from '@/libs/util.tool';
import { renderButton } from '@/libs/util';
import StoreModal from './StoreModal';
import StoreModalCurrent from './StoreModalCurrent';
import { getLabelByValue, storeDecorationStatusDict } from '@/dicts.js';
export default {
    components: { TableFormNew, TablePage, StoreModal, StoreModalCurrent },
    props: {
        year: {
            type: String,
            default: '',
        },
        formList: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    ...this.columns,
                    {
                        title: '操作',
                        key: 'oprate',
                        width: 120,
                        fixed: 'right',
                        align: 'center',
                        render: (h, p) => {
                            let btns = [h('span', '-')];
                            switch (this.year) {
                                case '2021':
                                    if (p.row.auditStatus == 3) {
                                        btns = [
                                            renderButton(h, { text: '查看', type: 'primary' }, () => {
                                                this.check(p.row, 'StoreModal');
                                            }),
                                        ];
                                    }
                                    break;
                                case '2022':
                                case '2023':
                                case '2024':
                                case '2025':
                                    if (p.row.auditStatus > 1) {
                                        btns = [
                                            renderButton(h, { text: '查看', type: 'primary' }, () => {
                                                this.check(p.row, 'StoreModalCurrent');
                                            }),
                                        ];
                                    }
                                    break;
                            }

                            return btns;
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 获取参数
        getParams() {
            const formData = this.$refs['tableFormNewRef'].getFormData();
            formData.deptId = formData?.dept?.[0];
            formData.regionId = formData?.dept?.[1];
            switch (this.year) {
                case '2021':
                    formData.startDate = `${formData?.startDate || '2021-12'}-01 00:00:00`;
                    break;
                case '2022':
                case '2023':
                case '2024':
                    formData.month = formData?.month ? formData?.month?.replace('-', '') : `${this.year}12`;
                    break;
                case '2025':
                    formData.month = dayjs(formData.month || new Date()).format('YYYYMM');
                    break;
            }
            const params = {
                ...formData,
                current: this.tableConfig.current,
                size: this.tableConfig.size,
            };
            return clearInvalidKey(params);
        },
        // 获取列表数据
        async getData() {
            try {
                this.tableConfig.loading = true;
                const _apiFn = this.year > 2021 ? storeInspectionList : storeAssessmentList;
                const res = await _apiFn(this.getParams());
                this.tableConfig.total = Number(res.total);
                this.tableConfig.data = res.records.map((item) => {
                    switch (this.year) {
                        case '2022':
                        case '2023':
                        case '2024':
                        case '2025':
                            item._applyTime = item.applyTime || '-';
                            item._channelManAuditResult = item.channelManAuditResult || '-';
                            if (this.year == '2025') {
                                item._buildStoreType = getLabelByValue(storeDecorationStatusDict, item.buildStoreType);
                            }
                            break;
                    }
                    return item;
                });
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.loading = false;
            }
        },
        // change-page
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        // change-size
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        // 导出数据
        async exportData() {
            try {
                this.$Message.loading({
                    content: '导出中...',
                    duration: 0,
                });
                const _apiFn = this.year > 2021 ? storeInspectionListExport : storeAssessmentListExport;
                const res = await _apiFn(this.getParams());
                this.$Message.destroy();
                if (!res) {
                    this.$Message.warning('暂无数据');
                    return false;
                }
                let aLink = document.createElement('a');
                aLink.href = res || '';
                document.body.appendChild(aLink);
                aLink.click();
                document.body.removeChild(aLink);
                this.$Notice.success({
                    title: '导出成功',
                });
            } catch (error) {
                this.$Message.destroy();
            }
        },
        // 查看
        check(row, refName) {
            this.$refs[refName].open(row, 1);
        },
    },
};
</script>

<style lang="less" scoped></style>
