// @ts-nocheck
import axios from 'axios';
import util, { jumpPage } from '@/libs/util';
import { Notice } from 'view-design';

// 创建一个错误
function errorCreate(msg) {
    const err = new Error(msg);

    // 打印到控制台
    util.log.error('>>>>>> Error >>>>>>');
    console.error(err);

    errorLog(err);
    throw err;
}

// 记录和显示错误
function errorLog(err) {
    Notice.error({
        title: '提示',
        desc: err.message,
        duration: 3,
    });
}

// 创建一个 axios 实例
const service = axios.create({
    baseURL: '/api',
    timeout: 60000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在请求发送之前做一些处理
        const token = util.cookies.get('Base-token');
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        config.headers['Authorization'] = token;
        return config;
    },
    (error) => {
        // 发送失败
        console.log(error);
        Promise.reject(error);
    },
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data;
        // 这个状态码是和后端约定的
        const { code } = dataAxios;
        // 根据 code 进行判断
        if (code === undefined) {
            // 如果没有 code 代表这不是项目后端开发的接口
            return dataAxios;
        } else {
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
                case 200:
                    // [ 示例 ] code === 0 代表没有错误
                    return dataAxios.data;
                case 100009:
                case 520001:
                case 520005:
                case 520006:
                case 520009:
                case 100006:
                    util.cookies.set('Base-token', '');
                    // [ 示例 ] 其它和后台约定的 code
                    jumpPage({ path: '/login', applyName: 'base' });
                    util.errorCreate(`身份认证失败`);
                    break;
                case 520000:
                case 100000:
                    errorCreate(`无访问权限`);
                    break;
                case 100013:
                    errorCreate(`账号被禁用`);
                    break;
                default:
                    // 不是正确的 code
                    errorCreate(dataAxios.message); // : ${response.config.url}`);

                    break;
            }
        }
    },
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        errorLog(error);
        return Promise.reject(error);
    },
);

export default service;
