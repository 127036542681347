// 字典集合

/**
 * @description 默认获取label值
 * @param {Array} options 字典数组
 * @param {Number} value value值，（如果是多个用','隔开，如:value:'1,2'）
 * @param {String} label 取某个key的值，默认label
 * @returns {String} 返回value对应的文本汉字值
 */
export const getLabelByValue = (options, value, label = 'label') => {
    if ([null, undefined, ''].includes(value)) {
        return '-';
    }
    let _list = options.filter((item) => String(value).split(',').includes(String(item.value)));
    let _label = _list.map((item) => item[label]).join('、');
    return _label;
};

/**
 * @description 截取部分字典
 * @param {Array} options 字典数组
 * @param {Array} values [1, 2] 要截取的value值数组
 * @param {Boolean} isSort 是否按照values排序
 */
export const sliceDictByValues = (options, values, isSort = false) => {
    if (isSort) {
        const arr = [];
        values.map((item) => {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === item) {
                    arr.push(options[i]);
                }
            }
        });
        return arr;
    }
    return options.filter((item) => values.includes(item.value));
};

// 平均库存
export const averageStocksDict = [
    { value: 30, label: '近30天平均库存' },
    { value: 60, label: '近60天平均库存' },
    { value: 90, label: '近90天平均库存' },
    { value: 120, label: '近120天平均库存' },
    { value: 180, label: '近180天平均库存' },
];

// 平均出库
export const averageOutboundsDict = [
    { value: 30, label: '近30天平均出库' },
    { value: 60, label: '近60天平均出库' },
    { value: 90, label: '近90天平均出库' },
    { value: 120, label: '近120天平均出库' },
    { value: 180, label: '近180天平均出库' },
];

// 【门店店检审核状态】
export const storeInspectAuditStatusDict = [
    { value: 1, label: '门店未提交' },
    { value: 2, label: '门店已提交' },
    { value: 3, label: '业务主管已审核' },
    { value: 4, label: '审核完成' },
];

// 【门店装修状态】
export const storeDecorationStatusDict = [
    { value: 0, label: '签约门店' },
    { value: 4, label: '挂牌店' },
    { value: 1, label: '快捷服务店' },
    { value: 2, label: '车空间店' },
];
